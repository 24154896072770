import React from 'react'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'

import Layout from '../components/layout'
import Banner from '../components/Banner'

// Project Tile Images
import hallmark from '../assets/images/hallmar-green.png'
import suddath from '../assets/images/suddath-green.png'
import t3sxsw from '../assets/images/t3sxsw-green.png'
import medialink from '../assets/images/medialink-green.png'
import lpq from '../assets/images/lpq-green.png'
import sunoco from '../assets/images/sunoco-green.png'
import t3 from '../assets/images/t3-green.png'
import pizzahut from '../assets/images/pizzahut-green.png'

// Slider Images
import allstate from '../assets/images/allstate.png'
import seveneleven from '../assets/images/seveneleven.png'
import ups from '../assets/images/ups.png'
import capitalone from '../assets/images/capitalone.png'
import gaygaddis from '../assets/images/gaygaddis.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Tyler Kemme | Full Stack Developer"
          meta={[
            {
              name: 'description',
              content: 'Full Stack Web Development and Design',
            },
            {
              name: 'keywords',
              content:
                'full stack, front end, react, golang, wordpress, python, django, scss, css, responsive',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <Banner />

        <div id="main">
          <section id="work" className="tiles">
            <article onClick={() => navigate('/hallmark')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${hallmark})`,
                }}
              />
              <header className="major">
                <h3>Hallmark</h3>
                <p>Full Stack Site Overhaul</p>
              </header>
            </article>
            <article onClick={() => navigate('/lpq')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${lpq})`,
                }}
              />
              <header className="major">
                <h3>Le Pain Quotidien</h3>
                <p>Online Ordering & Loyalty Platform</p>
              </header>
            </article>
            <article onClick={() => navigate('/sunoco')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${sunoco})`,
                }}
              />
              <header className="major">
                <h3>Sunoco</h3>
                <p>Headless Wordpress + React Site</p>
              </header>
            </article>
            <article onClick={() => navigate('/suddath')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${suddath})`,
                }}
              />
              <header className="major">
                <h3>Suddath</h3>
                <p>Headless Wordpress + React Site</p>
              </header>
            </article>
            <article onClick={() => navigate('/t3sxsw')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${t3sxsw})`,
                }}
              />
              <header className="major">
                <h3>
                
                
                
                SXSW</h3>
                <p>Web-based AR Application</p>
              </header>
            </article>
            <article onClick={() => navigate('/t3')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${t3})`,
                }}
              />
              <header className="major">
                <h3>T3</h3>
                <p>Wordpress Site Redesign</p>
              </header>
            </article>
            <article onClick={() => navigate('/pizzahut')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${pizzahut})`,
                }}
              />
              <header className="major">
                <h3>Pizza Hut</h3>
                <p>Site Redesign + Email Creation</p>
              </header>
            </article>
            <article onClick={() => navigate('/medialink')}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${medialink})`,
                }}
              />
              <header className="major">
                <h3>Medialink</h3>
                <p>Wordpress Site Redesign</p>
              </header>
            </article>
          </section>
          <section id="work-other">
            <div className="inner">
              <header className="minor">
                <h4>Other noteworthy companies I've developed for</h4>
              </header>
              <div className="work-other-companies">
                <div
                  className="company-logo allstate"
                  onClick={() =>
                    window.open('https://www.allstate.com/', '_blank')
                  }
                >
                  <img src={allstate} alt="allstate-logo" />
                </div>
                <div
                  className="company-logo seven-eleven"
                  onClick={() =>
                    window.open('https://www.7-eleven.com/7rewards', '_blank')
                  }
                >
                  <img src={seveneleven} alt="seven-eleven-logo" />
                </div>
                <div
                  className="company-logo ups"
                  onClick={() => window.open('https://www.ups.com/', '_blank')}
                >
                  <img src={ups} alt="ups-logo" />
                </div>
                <div
                  className="company-logo capital-one"
                  onClick={() =>
                    window.open('https://www.capitalone.com/', '_blank')
                  }
                >
                  <img src={capitalone} alt="capital-one-logo" />
                </div>
                <div
                  className="company-logo gay-gaddis"
                  onClick={() =>
                    window.open('https://www.gaygaddis.com/', '_blank')
                  }
                >
                  <img src={gaygaddis} alt="gay-gaddis-logo" />
                </div>
              </div>
            </div>
          </section>
          <section id="about">
            <div className="inner">
              <header className="major">
                <h2>About Me</h2>
              </header>
              <p>
                I'm a Full Stack Web Developer working at <a
                  href="https://klaviyo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Klaviyo
                </a> out of Austin, Texas.
                <br />
                <br />
                After getting my B.S in Computer Science at the{' '}
                <a
                  href="https://www.utexas.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Texas at Austin
                </a>
                , I began my journey into the world of Front-End Development.
                Since then, I have mastered the art of{' '}
                <a
                  href="https://www.w3.org/standards/webdesign/accessibility"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ADA compliant
                </a>
                , fully responsive web design using a variety of technologies. I
                always deliver pixel-perfect, performant sites and I can adapt
                to any requirement.
                <br />
                <br />
                Here are a few Front-End technologies I have used recently:
                <br />
                <ul className="skills-list">
                  <li>React</li>
                  <li>Next.js</li>
                  <li>
                    SCSS (I ❤️{' '}
                    <a
                      href="http://getbem.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BEM
                    </a>
                    )
                  </li>
                  <li>Node.js</li>
                  <li>Wordpress</li>
                </ul>
                As a Back-End Developer, I've created custom CMSs, complex payment
                and loyalty platforms, and{' '}
                <a
                  href="https://aws.amazon.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AWS
                </a>
                -backed systems that interact with a variety of third-party
                services.
                <br />
                <br />
                Here are some of the Back-End technologies I've worked with
                recently:
                <ul className="skills-list">
                  <li>Golang</li>
                  <li>Django</li>
                  <li>Redis</li>
                  <li>Express.js</li>
                  <li>AWS</li>
                  <li>MySQL/PostgreSQL</li>
                </ul>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
