import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Banner = props => (
  <section
    id="banner"
    className="major"
    // style={{
    //   backgroundImage: `url(https://images.unsplash.com/photo-1559506186-5f6b1f4d975f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80)`,
    // }}
  >
    <div className="inner">
      <header className="major">
        <h1>
          Hello! I’m Tyler Kemme and I'm a Full Stack Engineer based in
          Texas.
        </h1>
      </header>
      <div className="content">
        <p>Wanna know more?</p>
        <ul className="actions">
          <li>
            <div onClick={() => scrollTo('#about')} className="button next">
              About
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
